const Colors = {
  white: "#FFFFFF",
  jetBlack: "#2A2928",
  ceruleanCrayola: "#00A5CF",
  oxfordBlue: "#0F1630",
  platinumGray: "#EAEBEB",
  hoverBlue: "#222B4A",
};

export default Colors;
